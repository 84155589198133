import { ImgHTMLAttributes, forwardRef } from "react";

import clsx from "clsx";

type T = HTMLImageElement;
type P = ImgHTMLAttributes<T> & {
  selected?: boolean;
};

export const Figure = forwardRef<T, P>(
  ({ selected, className, ...props }, ref) => (
    <figure>
      <img
        ref={ref}
        className={clsx(
          "rounded-md border-none bg-white outline-none",
          selected && "ring-4 ring-indigo-500",
          className,
        )}
        {...props}
      />
    </figure>
  ),
);
