import { type FC } from "react";
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useDashboardVaultsDocumentsDetailsQuery } from "@app_schema";

import { Figure } from "@styled/figure";
import { Headline } from "@styled/headline";
import { Page } from "@styled/page";
import { Pane, PaneContent, PaneDrawer } from "@styled/pane";

import { DocumentDestroyButton } from "./document_destroy_button";
import { DocumentDownloadButton } from "./document_download_button";
import { PageViewer } from "./page_viewer";

export const DashboardVaultsDocumentsDetails: FC = () => {
  const [params] = useSearchParams();
  const number = params.has("page") ? Number(params.get("page")) : undefined;

  const { id: vaultID, documentID } = useParams<{
    id: string;
    documentID: string;
  }>();
  if (!vaultID) throw new Error("undefined vaultID");
  if (!documentID) throw new Error("undefined documentID");

  const navigate = useNavigate();

  const { data } = useDashboardVaultsDocumentsDetailsQuery({
    variables: {
      vaultID,
      documentID,
    },
  });

  if (!data) return null;
  const vault = data.vault;
  const document = vault.document;
  const pages = document.pages;

  const current = pages.find((page) => !number || page.number === number);

  return (
    <Page>
      <Headline title={`${vault.name} - ${document.name}`}>
        <DocumentDownloadButton document={document} />
        <DocumentDestroyButton
          vault={vault}
          document={document}
          onDestroy={() => navigate(`/dashboard/vaults/${vault.id}`)}
        />
      </Headline>

      <div className="h-[calc(100vh-164px)]">
        <Pane>
          <PaneDrawer>
            <div className="m-4 flex flex-col gap-4">
              {pages.map((page) => (
                <NavLink
                  key={page.id}
                  to={`/dashboard/vaults/${vaultID}/documents/${documentID}?page=${page.number}`}
                  className="flex cursor-pointer flex-col items-center gap-2 p-2"
                >
                  <Figure
                    loading="lazy"
                    width={page.width}
                    height={page.height}
                    src={page.file?.url}
                    selected={current && current.id === page.id}
                  />
                  <div>{page.number}</div>
                </NavLink>
              ))}
            </div>
          </PaneDrawer>
          <PaneContent>{current && <PageViewer page={current} />}</PaneContent>
        </Pane>
      </div>
    </Page>
  );
};
