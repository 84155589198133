import { type FC } from "react";

import { TemplateLanguage } from "@app_schema";

const NAMES = {
  [TemplateLanguage.Liquid]: "Liquid",
  [TemplateLanguage.Mustache]: "Mustache",
};

export const TemplateLanguageName: FC<{
  language: TemplateLanguage;
}> = ({ language }) => NAMES[language];
