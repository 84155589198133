import type { FC } from "react";

import { PageFragment } from "@app_schema";

import { Divider } from "@styled/divider";
import { Prose } from "@styled/prose";

import { PageStatus } from "./page_status";

export const PageViewer: FC<{
  page: PageFragment;
}> = ({ page }) => {
  const width = page.width > 0 ? page.width : 2550;
  const height = page.height > 0 ? page.height : 3300;

  return (
    <div className="flex h-full divide-x divide-slate-200">
      <div className="flex h-full w-full flex-1 items-center justify-center p-4">
        <img
          className="shadow-md"
          width={width}
          height={height}
          src={page.file?.url}
        />
      </div>
      <div className="flex h-full flex-1 flex-col gap-4 overflow-y-scroll p-4">
        <div className="flex items-center justify-between">
          <strong>{page.number}</strong>
          <PageStatus page={page} />
        </div>
        <Divider />
        {page.html && <Prose dangerouslySetInnerHTML={{ __html: page.html }} />}
      </div>
    </div>
  );
};
