import { forwardRef } from "react";

import { useDashboardVaultsFieldQuery } from "@app_schema";

import { SelectField, type SelectFieldProps } from "@styled/select_field";

type VaultSelectFieldProps = Omit<SelectFieldProps, "placeholder"> & {
  placeholder?: boolean;
};

export const VaultSelectField = forwardRef<
  HTMLSelectElement,
  VaultSelectFieldProps
>(({ placeholder, disabled, ...props }, ref) => {
  const { data, loading } = useDashboardVaultsFieldQuery();
  if (!data) return;

  const vaults = data.vaults.filter((vault) => !vault.deleted);

  return (
    <SelectField {...props} disabled={disabled || loading} ref={ref}>
      <option disabled={!placeholder}>- Vault -</option>
      {vaults.map((vault) => (
        <option key={vault.id} value={vault.id}>
          {vault.name}
        </option>
      ))}
    </SelectField>
  );
});
