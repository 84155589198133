import { clsx } from "clsx";

import { Color } from "./types";

type Size = "xs" | "sm" | "md" | "lg" | "xl";

const DEFAULT_COLOR: Color = "indigo";

const BUTTON_INDIGO_STYLE =
  "text-white bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-300 ring-indigo-200 shadow";

const BUTTON_ROSE_STYLE =
  "text-white bg-rose-500 hover:bg-rose-400 disabled:bg-rose-300 ring-rose-200 shadow";

const BUTTON_SLATE_STYLE =
  "text-white bg-slate-500 hover:bg-slate-400 disabled:bg-slate-300 ring-slate-200 shadow";

const BUTTON_WHITE_STYLE =
  "text-slate-600 hover:text-slate-800 bg-white hover:bg-slate-50 disabled:bg-gray-100 border-gray-200 border ring-slate-50";

const BUTTON_COLOR_STYLE: Record<Color, string> = {
  indigo: BUTTON_INDIGO_STYLE,
  rose: BUTTON_ROSE_STYLE,
  slate: BUTTON_SLATE_STYLE,
  white: BUTTON_WHITE_STYLE,
};

const BUTTON_SIZE_STYLE: Record<Size, string> = {
  xs: "text-xs",
  sm: "text-sm",
  md: "text-base",
  lg: "text-lg",
  xl: "text-xl",
};

const BUTTON_BASE_STYLE =
  "cursor-pointer inline-flex items-center justify-center gap-2 rounded-md px-3 py-2 font-semibold outline-none ring-0 transition focus:ring-4";

export const style = ({
  color = DEFAULT_COLOR,
  full,
  size,
  className,
}: {
  color?: Color;
  full?: boolean;
  size?: Size;
  className?: string;
}) =>
  clsx(
    BUTTON_BASE_STYLE,
    BUTTON_COLOR_STYLE[color],
    size && BUTTON_SIZE_STYLE[size],
    full && "w-full",
    className,
  );
