import { type FC } from "react";

import { ChatTarget } from "@app_schema";

const NAMES: Record<ChatTarget, string> = {
  [ChatTarget.AnthropicClaudeHaiku]: "Anthropic - Claude Haiku",
  [ChatTarget.AnthropicClaudeSonnet]: "Anthropic - Claude Sonnet",
  [ChatTarget.AnthropicClaudeOpus]: "Anthropic - Claude Opus",
  [ChatTarget.GoogleGeminiFlash]: "Google - Gemini Flash",
  [ChatTarget.GoogleGeminiPro]: "Google - Gemini Pro",
  [ChatTarget.GoogleGemini_1_5Flash]: "Google - Gemini 1.5 Flash",
  [ChatTarget.GoogleGemini_2_0Flash]: "Google - Gemini 2.0 Flash",
  [ChatTarget.GoogleGemini_1_5Pro]: "Google - Gemini 1.5 Pro",
  [ChatTarget.MistralSmall]: "Mistral - Small",
  [ChatTarget.MistralMedium]: "Mistral - Medium",
  [ChatTarget.MistralLarge]: "Mistral - Large",
  [ChatTarget.MistralCodestral]: "Mistral - Codestral",
  [ChatTarget.MistralPixtral]: "Mistral - Pixtral",
  [ChatTarget.OpenaiGpt_3_5Turbo]: "OpenAI - GPT 3.5 Turbo",
  [ChatTarget.OpenaiGpt_4]: "OpenAI - GPT 4",
  [ChatTarget.OpenaiGpt_4Turbo]: "OpenAI - GPT 4 Turbo",
  [ChatTarget.OpenaiGpt_4O]: "OpenAI - GPT 4o",
  [ChatTarget.OpenaiGpt_4OMini]: "OpenAI - GPT 4o-mini",
  [ChatTarget.OpenaiO1Mini]: "OpenAI - o1-mini",
  [ChatTarget.OpenaiO3Mini]: "OpenAI - o3-mini",
  [ChatTarget.OpenaiO1Preview]: "OpenAI - o1-preview",
  [ChatTarget.OpenaiO1]: "OpenAI - o1",
};

export const ChatTargetName: FC<{
  target: ChatTarget;
}> = ({ target }) => NAMES[target];
