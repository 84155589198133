import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLDivElement;
type P = HTMLAttributes<T> & {
  bordered?: boolean;
};

export const Field = forwardRef<T, P>(
  ({ bordered, className, ...props }, ref) => (
    <div
      ref={ref}
      className={clsx(
        "flex w-full flex-col gap-2",
        bordered && "rounded-md border border-slate-200 p-4",
        className,
      )}
      {...props}
    />
  ),
);

Field.displayName = "Field";
