import { type FC, type ReactNode } from "react";
import { NavLink } from "react-router-dom";

import { clsx } from "clsx";

export const TabNavLink: FC<{
  children: ReactNode;
  to: string;
}> = ({ to, children }) => (
  <NavLink
    to={to}
    className={({ isActive, isPending }) =>
      clsx(
        "-mb-px flex items-center gap-2 border-b-2 px-3 py-2 font-medium",
        isActive || isPending
          ? "border-indigo-500 text-indigo-500"
          : "border-transparent text-slate-400 hover:border-slate-600 hover:text-slate-600",
      )
    }
  >
    {children}
  </NavLink>
);
