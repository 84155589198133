import { DASHBOARD_ARTIFACTS_FIELD_LOADER } from "./dashboard_artifacts_field_loader";
import { DASHBOARD_CONNECTIONS_FIELD_LOADER } from "./dashboard_connections_field_loader";
import { DASHBOARD_VAULTS_FIELD_LOADER } from "./dashboard_vaults_field_loader";
import { DASHBOARD_WORKFLOWS_FIELD_LOADER } from "./dashboard_workflows_field_loader";

export const DASHBOARD_SCHEDULES_FORM_LOADER = async () => {
  const LOADERS = [
    DASHBOARD_ARTIFACTS_FIELD_LOADER,
    DASHBOARD_CONNECTIONS_FIELD_LOADER,
    DASHBOARD_VAULTS_FIELD_LOADER,
    DASHBOARD_WORKFLOWS_FIELD_LOADER,
  ];

  await Promise.all(LOADERS.map((loader) => loader()));
};
