import { type SelectHTMLAttributes, forwardRef } from "react";

import { TemplateLanguage } from "@app_schema";

import { type BaseFieldProps } from "@styled/base_field";
import { SelectField } from "@styled/select_field";

import { TemplateLanguageName } from "./template_language_name";

type T = HTMLSelectElement;
type P = BaseFieldProps &
  SelectHTMLAttributes<T> & {
    placeholder?: boolean;
  };

const LANGUAGES = [TemplateLanguage.Liquid, TemplateLanguage.Mustache];

export const TemplateLanguageSelectField = forwardRef<T, P>(
  ({ label = "Language:", placeholder, ...props }, ref) => (
    <SelectField ref={ref} label={label} {...props}>
      <option value="" disabled={!placeholder}>
        - Language -
      </option>
      {LANGUAGES.map((language) => (
        <option key={language} value={language}>
          <TemplateLanguageName language={language} />
        </option>
      ))}
      <option value={TemplateLanguage.Liquid}>Liquid</option>
    </SelectField>
  ),
);
