import { type LoaderFunctionArgs } from "react-router";

import {
  DashboardVaultsDocumentsDetailsDocument,
  type DashboardVaultsDocumentsDetailsQuery,
  type DashboardVaultsDocumentsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_VAULTS_DOCUMENTS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { id: vaultID, documentID } = props.params;
  if (!vaultID) throw new Error("undefined vaultID");
  if (!documentID) throw new Error("undefined documentID");

  const { data } = await client.query<
    DashboardVaultsDocumentsDetailsQuery,
    DashboardVaultsDocumentsDetailsQueryVariables
  >({
    query: DashboardVaultsDocumentsDetailsDocument,
    variables: {
      vaultID,
      documentID,
    },
  });

  return data;
};
