import { FC, ReactNode } from "react";

export const PaneDrawer: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="h-full w-48 shrink-0 grow-0 overflow-y-scroll bg-slate-100">
    {children}
  </div>
);

export const PaneContent: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="h-full shrink grow overflow-y-scroll">{children}</div>
);

export const Pane: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="flex h-full divide-x divide-slate-200 overflow-hidden rounded-md border border-slate-200">
    {children}
  </div>
);
