import { type FC } from "react";

import { faCheck } from "@fortawesome/pro-duotone-svg-icons/faCheck";
import { faCopy } from "@fortawesome/pro-duotone-svg-icons/faCopy";

import { type Execution, type RunFragment } from "@app_schema";

import { useClipboard } from "@application/hooks/use_clipboard";

import { IconButton } from "@styled/icon_button";

export const ExecutionCopyIconButton: FC<{
  run: RunFragment;
  execution: Pick<Execution, "result">;
}> = ({ execution }) => {
  if (!execution.result) return <IconButton disabled icon={faCopy} />;

  const { copy, copied } = useClipboard(execution.result);

  return <IconButton onClick={copy} icon={copied ? faCheck : faCopy} />;
};
