import { type FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { faPlusSquare } from "@fortawesome/pro-duotone-svg-icons/faPlusSquare";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { faUndo } from "@fortawesome/pro-duotone-svg-icons/faUndo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  type ConditionInput,
  ConditionOperationEnum,
  type StepInput,
} from "@app_schema";

import { Button } from "@styled/button";
import { Field } from "@styled/field";
import { Fields } from "@styled/fields";
import { Group } from "@styled/group";
import { InputField } from "@styled/input_field";
import { Label } from "@styled/label";
import { SelectField } from "@styled/select_field";

const OPERATIONS = [
  ConditionOperationEnum.Eq,
  ConditionOperationEnum.Neq,
  ConditionOperationEnum.Gt,
  ConditionOperationEnum.Gte,
  ConditionOperationEnum.Lt,
  ConditionOperationEnum.Lte,
  ConditionOperationEnum.Contains,
];

const OPERATION_LABELS: Record<ConditionOperationEnum, string> = {
  [ConditionOperationEnum.Eq]: "=",
  [ConditionOperationEnum.Neq]: "≠",
  [ConditionOperationEnum.Gt]: ">",
  [ConditionOperationEnum.Gte]: "≥",
  [ConditionOperationEnum.Lt]: "<",
  [ConditionOperationEnum.Lte]: "≤",
  [ConditionOperationEnum.Contains]: "contains",
};

const DEFAULT_CONDITION: ConditionInput = {
  lhs: "",
  rhs: "",
  operation: ConditionOperationEnum.Eq,
};

export const DashboardStepsFormConditionsFieldset: FC = () => {
  const form = useFormContext<StepInput>();

  const { fields, append, update, remove } = useFieldArray({
    control: form.control,
    name: "conditions",
    keyName: "key",
  });

  const onDelete = (index: number) => {
    const { id, _destroy } = fields[index];
    if (id) update(index, { id, _destroy: !_destroy });
    else remove(index);
  };

  const onRestore = (index: number) => {
    const { id, _destroy } = fields[index];
    update(index, { id, _destroy: !_destroy });
  };

  const conditions = fields;

  return (
    <Field>
      <Label>Conditions:</Label>

      <Group>
        {conditions.map((field, index) => (
          <Fields key={field.key}>
            <InputField
              disabled={!!field._destroy}
              {...form.register(`conditions.${index}.lhs`, {
                required: "required",
              })}
              id={`step_conditions_${index}_lhs`}
              placeholder="LHS"
            />

            <SelectField
              full
              disabled={!!field._destroy}
              {...form.register(`conditions.${index}.operation`, {
                required: "required",
              })}
              id={`step_conditions_${index}_operation`}
            >
              <option
                disabled={!!form.watch(`conditions.${index}.operation`)}
                value=""
              >
                - Kind -
              </option>
              {OPERATIONS.map((operation) => (
                <option key={operation} value={operation}>
                  {OPERATION_LABELS[operation]}
                </option>
              ))}
            </SelectField>

            <InputField
              disabled={!!field._destroy}
              {...form.register(`conditions.${index}.rhs`, {
                required: "required",
              })}
              id={`step_conditions_${index}_rhs`}
              placeholder="RHS"
            />

            {field._destroy ? (
              <Button
                type="button"
                color="rose"
                onClick={() => onRestore(index)}
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>
            ) : (
              <Button
                type="button"
                color="rose"
                onClick={() => onDelete(index)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </Fields>
        ))}

        <div>
          <Button type="button" onClick={() => append(DEFAULT_CONDITION)}>
            <FontAwesomeIcon icon={faPlusSquare} />
            Condition
          </Button>
        </div>
      </Group>
    </Field>
  );
};
