import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { useDashboardVaultsFieldQuery } from "@app_schema";

import { BaseField } from "@styled/base_field";
import { LabelCheckbox } from "@styled/label_checkbox";
import { LabelCheckboxDescription } from "@styled/label_checkbox_description";
import { LabelCheckboxName } from "@styled/label_checkbox_name";

export const DashboardVaultsField: FC<{
  name: string;
  label?: string;
}> = ({ name }) => {
  const { register } = useFormContext();
  const { data } = useDashboardVaultsFieldQuery();
  const vaults = data?.vaults.filter((vault) => !vault.deleted);

  return (
    <BaseField name="vault_ids" label="Vaults:">
      {() => (
        <div className="flex flex-col gap-2">
          {vaults?.map((vault) => (
            <LabelCheckbox {...register(name)} value={vault.id}>
              <LabelCheckboxName>{vault.name}</LabelCheckboxName>
              <LabelCheckboxDescription>
                {vault.description}
              </LabelCheckboxDescription>
            </LabelCheckbox>
          ))}
        </div>
      )}
    </BaseField>
  );
};
