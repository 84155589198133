import { type SelectHTMLAttributes, forwardRef } from "react";

import { ChatTarget } from "@app_schema";

import { type BaseFieldProps } from "@styled/base_field";
import { SelectField } from "@styled/select_field";

import { ChatTargetName } from "./chat_target_name";

type T = HTMLSelectElement;
type P = BaseFieldProps &
  SelectHTMLAttributes<T> & {
    placeholder?: boolean;
  };

const GROUPS = [
  {
    name: "Anthropic",
    targets: [
      ChatTarget.AnthropicClaudeHaiku,
      ChatTarget.AnthropicClaudeSonnet,
      ChatTarget.AnthropicClaudeOpus,
    ],
  },
  {
    name: "Google",
    targets: [
      ChatTarget.GoogleGeminiFlash,
      ChatTarget.GoogleGeminiPro,
      ChatTarget.GoogleGemini_1_5Flash,
      ChatTarget.GoogleGemini_2_0Flash,
      ChatTarget.GoogleGemini_1_5Pro,
    ],
  },
  {
    name: "Mistral",
    targets: [
      ChatTarget.MistralSmall,
      ChatTarget.MistralMedium,
      ChatTarget.MistralLarge,
      ChatTarget.MistralCodestral,
      ChatTarget.MistralPixtral,
    ],
  },
  {
    name: "OpenAI",
    targets: [
      ChatTarget.OpenaiGpt_3_5Turbo,
      ChatTarget.OpenaiGpt_4,
      ChatTarget.OpenaiGpt_4Turbo,
      ChatTarget.OpenaiGpt_4O,
      ChatTarget.OpenaiGpt_4OMini,
      ChatTarget.OpenaiO1Mini,
      ChatTarget.OpenaiO3Mini,
      ChatTarget.OpenaiO1Preview,
      ChatTarget.OpenaiO1,
    ],
  },
];

export const ChatTargetSelectField = forwardRef<T, P>(
  ({ label = "LLM:", placeholder, ...props }, ref) => (
    <SelectField ref={ref} label={label} {...props}>
      <option value="" disabled={!placeholder}>
        - Select -
      </option>
      {GROUPS.map(({ name, targets }) => (
        <optgroup key={name} label={name}>
          {targets.map((target) => (
            <option key={target} value={target}>
              <ChatTargetName target={target} />
            </option>
          ))}
        </optgroup>
      ))}
    </SelectField>
  ),
);
