import { type FC, type ReactNode } from "react";

import {
  type DocumentFragment,
  type VaultFragment,
  useVaultDocumentAttachMutation,
} from "@app_schema";

import { ByteSize } from "@styled/byte_size";
import { FileIcon } from "@styled/file_icon";
import { FileManager } from "@styled/file_manager";
import { FilePicker } from "@styled/file_picker";
import { FileQueue } from "@styled/file_queue";
import { FileStatus } from "@styled/file_status";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemContent } from "@styled/list_item_content";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemIcon } from "@styled/list_item_icon";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { Progress } from "@styled/progress";
import { ViewLink } from "@styled/view_link";

import { DocumentDestroyButton } from "./document_destroy_button";
import { DocumentDownloadButton } from "./document_download_button";
import { DocumentStatus } from "./document_status";

const FileListItem: FC<{
  name: string;
  type?: string | null;
  size?: number | null;
  controls: ReactNode;
  progress?: {
    loaded: number;
    total: number;
  };
}> = ({ name, type, size, controls, progress }) => (
  <ListItem>
    <ListItemContent>
      <ListItemIcon>
        <FileIcon type={type} />
      </ListItemIcon>
      <ListItemText>
        <ListItemTitle>{name}</ListItemTitle>
        <ListItemSummary>
          <ByteSize bytes={size} />
        </ListItemSummary>
      </ListItemText>
      <ListItemControls>{controls}</ListItemControls>
    </ListItemContent>
    {progress && <Progress value={progress.loaded / progress.total} />}
  </ListItem>
);

export const VaultsDocuments: FC<{
  vault: VaultFragment & {
    documents: DocumentFragment[];
  };
}> = ({ vault }) => {
  const [attach] = useVaultDocumentAttachMutation();

  const save = async (blob: { signed_id: string }) => {
    await attach({
      variables: {
        vaultID: vault.id,
        input: { fileID: blob.signed_id },
      },
    });
  };

  return (
    <FileManager save={save}>
      <FilePicker />

      <List>
        <FileQueue
          render={({ file, progress }) => (
            <FileListItem
              name={file.name}
              type={file.type}
              size={file.size}
              controls={<FileStatus {...progress} />}
              progress={progress}
            />
          )}
        />

        {vault.documents.map((document) => (
          <FileListItem
            key={document.id}
            name={document.name}
            type={document.file.type}
            size={document.file.bytesize}
            controls={
              <>
                <DocumentStatus document={document} />
                <DocumentDownloadButton document={document} />
                <ViewLink
                  to={`/dashboard/vaults/${vault.id}/documents/${document.id}`}
                />
                <DocumentDestroyButton document={document} vault={vault} />
              </>
            }
          />
        ))}
      </List>
    </FileManager>
  );
};
