import { FC } from "react";

import { faFileDownload } from "@fortawesome/pro-duotone-svg-icons/faFileDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DocumentFragment } from "@app_schema";

import { ButtonAnchor } from "@styled/button_anchor";

export const DocumentDownloadButton: FC<{
  document: DocumentFragment;
}> = ({ document }) => (
  <ButtonAnchor href={document.file.url} download>
    <FontAwesomeIcon icon={faFileDownload} />
    Download
  </ButtonAnchor>
);
