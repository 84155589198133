import { type FC } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { useDashboardSkillsDetailsQuery } from "@app_schema";

import { Code } from "@styled/code";
import { Headline } from "@styled/headline";
import { ModifyLink } from "@styled/modify_link";
import { Page } from "@styled/page";
import { Pill } from "@styled/pill";
import { Prose } from "@styled/prose";
import { Subtitle } from "@styled/subtitle";
import { Well } from "@styled/well";

import { SkillDestroyButton } from "./skill_destroy_button";
import { SkillPackageButton } from "./skill_package_button";
import { SkillPackageLink } from "./skill_package_link";
import { SkillVerifyButton } from "./skill_verify_button";

export const DashboardSkillsDetails: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error, refetch } = useDashboardSkillsDetailsQuery({
    variables: { id: id! },
  });

  const retry = () => refetch({ id: id! });

  const skill = data?.skill;
  if (!skill) return null;

  return (
    <Page loading={loading} error={error} retry={retry}>
      <Headline
        title={
          <>
            {skill.name} (v{skill.version})
          </>
        }
      >
        <ModifyLink to={`/dashboard/skills/${skill.id}/edit`} />
        <SkillPackageLink skill={skill} />
        <SkillPackageButton skill={skill} />
        <SkillVerifyButton skill={skill} />
        <SkillDestroyButton skill={skill} />
      </Headline>

      {skill.dependencies.length > 0 && (
        <>
          <Subtitle>Dependencies:</Subtitle>
          <div className="flex gap-4">
            {skill.dependencies.map((dependency) => (
              <Link
                to={`/dashboard/skills/${dependency.id}`}
                key={dependency.id}
              >
                <Pill color="indigo">
                  {dependency.name} (v{dependency.version})
                </Pill>
              </Link>
            ))}
          </div>
        </>
      )}

      {skill.description && (
        <>
          <Subtitle>Description:</Subtitle>
          <p>{skill.description}</p>
        </>
      )}

      {skill.parametersHTML && (
        <>
          <Subtitle>Parameters:</Subtitle>
          <Code>{skill.parametersHTML}</Code>
        </>
      )}

      <Subtitle>Code:</Subtitle>
      <Code>{skill.codeHTML}</Code>

      <Subtitle>Test:</Subtitle>
      <Code>{skill.testHTML}</Code>

      <Subtitle>Documentation:</Subtitle>
      <Well>
        <Prose dangerouslySetInnerHTML={{ __html: skill.documentationHTML }} />
      </Well>
    </Page>
  );
};
