import { type LoaderFunctionArgs } from "react-router";

import {
  DashboardVaultsDetailsDocument,
  type DashboardVaultsDetailsQuery,
  type DashboardVaultsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_VAULTS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const id = props.params.id;
  if (!id) throw new Error("undefined ID");

  const { data } = await client.query<
    DashboardVaultsDetailsQuery,
    DashboardVaultsDetailsQueryVariables
  >({
    query: DashboardVaultsDetailsDocument,
    variables: { id },
  });

  return data;
};
